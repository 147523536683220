import { isSomeEnum } from '@dce-front/onewebapp-utils';

export const VideoType = {
  Pfv: 'pfv',
  Hapi: 'hapi',
  Dailymotion: 'dailymotion',
  Youtube: 'youtube',
} as const;

export type VideoType = (typeof VideoType)[keyof typeof VideoType];

export const isVideoType = isSomeEnum(VideoType);
