import type { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import type { ApiV2ArticleFragment } from '@dce-front/hodor-types/api/v2/article/definitions';
import { castToEnum } from '@dce-front/onewebapp-utils';
import type { OfferLocation, OfferZone } from '@dce-front/sdk-core';
import { FragmentContentType } from '@dce-front/sdk-hodor';
import type { JSX } from 'react';
import { VideoType } from '../../constants/videoTypes';
import { AdvertisingFragment } from '../../Fragments/AdvertisingFragment/AdvertisingFragment';
import { CTAFragment } from '../../Fragments/CTAFragment/CTAFragment';
import { DownloadFragment } from '../../Fragments/DownloadFragment/DownloadFragment';
import { IFrameFragment } from '../../Fragments/IFrameFragment/IFrameFragment';
import { IntroductionFragment } from '../../Fragments/IntroductionFragment/IntroductionFragment';
import { PictureFragment } from '../../Fragments/PictureFragment/PictureFragment';
import { QuoteFragment } from '../../Fragments/QuoteFragment/QuoteFragment';
import { RawFragment } from '../../Fragments/RawFragment/RawFragment';
import { TitleFragment } from '../../Fragments/TitleFragment/TitleFragment';
import { TwitterFragment } from '../../Fragments/TwitterFragment/TwitterFragment';
import { VideoFragment } from '../../Fragments/VideoFragment/VideoFragment';
import type { ApiV2ArticleExtendedFragmentContent } from '../../types';
import { extractAndParseJSON } from './extractJson';
import type { ThumborQuality } from '@dce-front/dive';

export const getComponentByType = (
  { content, type }: ApiV2ArticleFragment,
  key: React.Key,
  offerLocation: OfferLocation,
  offerZone: OfferZone,
  qualityUserSettings: ThumborQuality,
  playerCredentials?: IAPICredentialsRaw,
): JSX.Element | null => {
  // If the fragment does not have content, it can't render!
  if (!content) {
    return null;
  }

  const {
    text,
    title,
    height,
    media,
    links,
    id,
    source,
    documents,
    html,
    js,
    css,
    button,
    description,
    URLImage,
    altImage,
  } = content as ApiV2ArticleExtendedFragmentContent;

  switch (type) {
    case FragmentContentType.Title:
      return text ? <TitleFragment key={key} title={text} /> : null;

    case FragmentContentType.Introduction:
      return text ? <IntroductionFragment key={key} text={text} /> : null;

    // FragmentType.Text and FragmentType.Raw have the same functionality
    case FragmentContentType.Text:
    case FragmentContentType.Raw: {
      if (!text) {
        return null;
      }
      // Legacy support, Quick fix for support Twitter fragment
      if (/\b(https:\/\/platform.twitter.com\/widgets.js)\b/.test(text || '')) {
        return <TwitterFragment key={key} text={text} />;
      }
      // Try to parse FragmentType.Raw/Text content text
      // If it is neither a JSON format nor an ApiV2ArticleFragment, return the Raw fragment; otherwise, replace the Raw fragment with the corresponding fragment.
      // It's to manage fragment not supported by creative media back office to use custom fragment more maintainable instead of inject html
      const jsonFromRawText = extractAndParseJSON(text);

      if (!jsonFromRawText || !isApiV2ArticleFragment(jsonFromRawText)) {
        return <RawFragment text={text} key={key} />;
      }

      return getComponentByType(
        jsonFromRawText,
        key,
        offerLocation,
        offerZone,
        qualityUserSettings,
        playerCredentials,
      );
    }

    case FragmentContentType.Partner:
    case FragmentContentType.CTA:
      return (
        <CTAFragment
          button={button}
          description={description}
          URLImage={URLImage}
          altImage={altImage}
          qualityUserSettings={qualityUserSettings}
          key={key}
        />
      );

    case FragmentContentType.Download:
      return title ? (
        <DownloadFragment key={key} title={title} documents={documents} />
      ) : null;

    case FragmentContentType.Video: {
      const isAvailable = key !== 0 && id !== undefined && source !== undefined;

      return isAvailable ? (
        <VideoFragment
          key={key}
          playerCredentials={playerCredentials}
          id={id.toString()}
          type={castToEnum(VideoType)(source)}
          offerLocation={offerLocation}
          offerZone={offerZone}
        />
      ) : null;
    }

    case FragmentContentType.Image:
      return media?.links?.[0]?.href ? (
        <PictureFragment
          key={key}
          url={media?.links?.[0].href}
          description={media?.description}
          qualityUserSettings={qualityUserSettings}
        />
      ) : null;

    case FragmentContentType.Quote:
      return text ? <QuoteFragment key={key} text={text} /> : null;

    case FragmentContentType.Iframe:
      return (
        <IFrameFragment key={key} url={links?.[0]?.href} height={height} />
      );

    case FragmentContentType.Advertising:
      return <AdvertisingFragment key={key} html={html} js={js} css={css} />;

    case FragmentContentType.Twitter:
      return text ? <TwitterFragment key={key} text={text} /> : null;

    default:
      return null;
  }
};

function isApiV2ArticleFragment(
  fragment: unknown,
): fragment is ApiV2ArticleFragment {
  const fragmentTemp = fragment as ApiV2ArticleFragment;

  return (
    fragmentTemp.type !== undefined &&
    Object.values(FragmentContentType).includes(
      fragmentTemp.type as FragmentContentType,
    ) &&
    (fragment as ApiV2ArticleFragment).content !== undefined
  );
}
