import { ButtonIcon, ShareFacebookSvg, ShareTwitterSvg } from '@dce-front/dive';
import type { SocialMedia } from '@dce-front/hodor-types/modules/article/definitions';
import { ShareButtonWrapper } from '@dce-front/sharedcomponent';
import classNames from 'classnames';
import type { JSX } from 'react';
import { SHARINGLINKS } from '../../constants/sharing';
import { useTranslation } from '../../lang';
import styles from './SocialFragment.module.css';

export type SocialFragmentProps = {
  data: {
    facebook?: SocialMedia;
    twitter?: SocialMedia;
    linkedin?: SocialMedia;
  };
  sharingURL?: string;
  title?: string;
};

export function SocialFragment({
  data,
  sharingURL = '',
  title = '',
}: SocialFragmentProps): JSX.Element {
  const { facebook, twitter } = data;
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.SocialFragment)}>
      {facebook && facebook.isActive && (
        <ShareButtonWrapper
          sharingURL={sharingURL}
          shareText={title}
          type={SHARINGLINKS.FACEBOOK}
          ariaLabel={`${t('CreativeMedia.Sharing.title')} ${SHARINGLINKS.FACEBOOK}`}
        >
          <ButtonIcon icon={<ShareFacebookSvg title={title} />} as="div" />
        </ShareButtonWrapper>
      )}
      {twitter && twitter.isActive && (
        <ShareButtonWrapper
          sharingURL={sharingURL}
          shareText={t('ActionBar.twitterShare', { movie: title })}
          type={SHARINGLINKS.TWITTER}
          ariaLabel={`${t('CreativeMedia.Sharing.title')} ${SHARINGLINKS.TWITTER}`}
        >
          <ButtonIcon icon={<ShareTwitterSvg title={title} />} as="div" />
        </ShareButtonWrapper>
      )}
    </div>
  );
}
