/* eslint-disable react/no-array-index-key */
import type { APIV2ArticleFragmentInformation } from '@dce-front/hodor-types/api/v2/article/definitions';
import { ContentRow, TemplateHeader } from '@dce-front/sharedcomponent';
import classNames from 'classnames/bind';
import { useId, useMemo, type JSX } from 'react';
import type { CreativeMediaProps } from '../../CreativeMedia';
import { CreativeMediaCard } from '../../CreativeMediaCard/CreativeMediaCard';
import { useTranslation } from '../../lang';
import styles from './RelatedArticleFragment.module.css';

const cx = classNames.bind(styles);

export type RelatedArticleFragmentProps = {
  articles: APIV2ArticleFragmentInformation[];
} & Pick<
  CreativeMediaProps,
  'isImmersive' | 'Linker' | 'qualityUserSettings' | 'renderLinker'
>;

export function RelatedArticleFragment({
  articles,
  isImmersive,
  Linker,
  qualityUserSettings,
  renderLinker,
}: RelatedArticleFragmentProps): JSX.Element {
  const { t } = useTranslation();
  const id = useId();
  const labels = useMemo(
    () => ({
      next: t('HorizontalPaging.next'),
      previous: t('HorizontalPaging.previous'),
    }),
    [t],
  );

  const articleItems = articles.map(
    ({ onClick: mainOnClick, URLImage, title, description }, index) => {
      return (
        <li
          key={`${id}${index}`}
          className={cx('RelatedArticleFragment__card', {
            'RelatedArticleFragment__card--immersive': isImmersive,
          })}
        >
          {renderLinker({
            mainOnClick,
            children: URLImage && (
              <CreativeMediaCard
                title={title}
                image={URLImage}
                qualityUserSettings={qualityUserSettings}
                t={t}
                isImmersive={isImmersive}
                description={description}
              />
            ),
          })}
        </li>
      );
    },
  );

  if (isImmersive) {
    return (
      <div className={cx('RelatedArticleFragment')}>
        <ContentRow
          header={{ title: t('CreativeMedia.RelatedArticles.heading') }}
          isFromCreativeMedia
          labels={labels}
          Linker={Linker}
        >
          {articleItems}
        </ContentRow>
      </div>
    );
  }

  return (
    <div className={cx('RelatedArticleFragment')}>
      <div className={cx('RelatedArticleFragment__header')}>
        <TemplateHeader
          displayBigTitle={false}
          isFromCreativeMedia
          Linker={Linker}
          title={t('CreativeMedia.RelatedArticles.heading')}
        />
      </div>
      <ul className={cx('RelatedArticleFragment__contentGrid')}>
        {articleItems}
      </ul>
    </div>
  );
}
